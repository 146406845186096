module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Polyphene","short_name":"Polyphene","start_url":"/","background_color":"#eeeeee","theme_color":"#b780ff","display":"minimal-ui","icon":"/home/runner/work/polyphene.io/polyphene.io/static/assets/polyphene_icon_256.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"334cf410eaf9944b17d6f2f1a205c00d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":2,"matomoUrl":"https://analytics.polyphene.io","siteUrl":"https://polyphene.io"},
    }]
